import { createContext, useEffect, useState } from "react";
import auth from "../../../helpers/auth";
import LoadingPage from "../../../layout/LoadingPage";
import {
  APP_ONLY,
  BRANCH_MANAGER,
  CAN_ARCHIVE_COMPLAINTS,
  CAN_ARCHIVE_REVIEWS,
  CAN_MANAGE_BRANCHES,
  CAN_MANAGE_DIVISIONS,
  CAN_MANAGE_LABELS,
  CAN_MANAGE_USERS,
  CAN_MANAGE_WORKSPACE,
  CAN_SEND_SMS,
  CAN_USE_FEEDBACK_INVITATIONS,
  CAN_USE_REPUTATION_DASHBOARD,
  CAN_USE_SETTINGS,
  CAN_USE_GOVERNORATE_LAYER,
  DEMO,
  DIVISION_MANAGER,
  FACILITY_MANAGER,
  FEEDBACK_ONLY,
} from "../../../helpers/constants";

export const AuthenticationContext = createContext();

const AuthenticationContextProvider = (props) => {
  const [user, setUser] = useState(null);
  const [fetched, setFetched] = useState(false);
  const [isFacilityManager, setIsFacilityManager] = useState(false);
  const [isBranchManager, setIsBranchManager] = useState(false);
  const [isDivisionManager, setIsDivisionManager] = useState(false);
  const [isFeedbackOnly, setIsFeedbackOnly] = useState(false);
  const [isAppOnly, setIsAppOnly] = useState(false);
  const [isDemo, setIsDemo] = useState(false);
  const [canSendSMS, setCanSendSMS] = useState(false);
  const [canManageDivisions, setCanManageDivisions] = useState(false);
  const [canManageWorkspace, setCanManageWorkspace] = useState(false);
  const [canManageLabels, setCanManageLabels] = useState(false);
  const [canManageUsers, setCanManageUsers] = useState(false);
  const [canUseSettings, setCanUseSettings] = useState(false);
  const [canUseFeedbackInvitations, setCanUseFeedbackInvitations] =
    useState(false);
  const [canArchiveReviews, setCanArchiveReviews] = useState(false);
  const [canUseReputationDashboard, setCanUseReputationDashboard] =
    useState(false);
  const [canArchiveComplaints, setCanArchiveComplaints] = useState(false);
  const [canUseGovernorateLayer, setCanUseGovernorateLayer] = useState(false);
  const [hasSignedInGoogle, setHasSignedInGoogle] = useState(null);

  const resetState = () => {
    resetRoles();
    resetProfileFeatures();
    setHasSignedInGoogle(null);
  };

  const resetRoles = () => {
    setUser(null);
    setIsFacilityManager(false);
    setIsBranchManager(false);
    setIsDivisionManager(false);
    setIsFeedbackOnly(false);
    setIsAppOnly(false);
    setIsDemo(false);
  };

  const resetProfileFeatures = () => {
    setCanSendSMS(false);
    setCanManageDivisions(false);
    setCanManageUsers(false);
    setCanUseSettings(false);
    setCanUseFeedbackInvitations(false);
    setCanArchiveReviews(false);
    setCanArchiveComplaints(false);
    setCanUseReputationDashboard(false);
    setCanManageWorkspace(false);
    setCanManageLabels(false);
  };

  const login = (username, password) => {
    return auth.login(username, password).then((data) => {
      setUser(data.data);
    });
  };

  const logout = async () => {
    resetState();
    await auth.logout();
  };

  const getUser = async () => {
    const user = await auth.getUser();
    setUser(user ? user.data : null);
  };
  const setUserProfile = (profile) => {
    setUser({ ...user, profile: profile });
  };

  //ROLES
  useEffect(() => {
    const userIsRole = (role) => {
      return user?.role === role;
    };

    if (userIsRole(FACILITY_MANAGER)) return setIsFacilityManager(true);
    if (userIsRole(BRANCH_MANAGER)) return setIsBranchManager(true);
    if (userIsRole(DIVISION_MANAGER)) return setIsDivisionManager(true);
    if (userIsRole(APP_ONLY)) return setIsAppOnly(true);
    if (userIsRole(FEEDBACK_ONLY)) return setIsFeedbackOnly(true);
    if (userIsRole(DEMO)) return setIsDemo(true);
  }, [user]);

  //PROFILE FEATURES
  useEffect(() => {
    const userHasFeature = (feature) => {
      return !!user?.profile?.features?.includes(feature);
    };
    setHasSignedInGoogle(!!user?.profile?.has_signed_in_google);
    if (userHasFeature(CAN_MANAGE_DIVISIONS)) setCanManageDivisions(true);
    if (userHasFeature(CAN_MANAGE_USERS)) setCanManageUsers(true);
    if (userHasFeature(CAN_SEND_SMS)) setCanSendSMS(true);
    if (userHasFeature(CAN_USE_SETTINGS)) setCanUseSettings(true);
    if (userHasFeature(CAN_USE_FEEDBACK_INVITATIONS))
      setCanUseFeedbackInvitations(true);
    if (userHasFeature(CAN_MANAGE_BRANCHES)) setCanManageUsers(true);
    if (userHasFeature(CAN_USE_REPUTATION_DASHBOARD))
      setCanUseReputationDashboard(true);
    if (userHasFeature(CAN_ARCHIVE_REVIEWS) && user["can_archive_reviews"])
      setCanArchiveReviews(true);
    if (
      userHasFeature(CAN_ARCHIVE_COMPLAINTS) &&
      user["can_archive_complaints"]
    )
      setCanArchiveComplaints(true);
    if (userHasFeature(CAN_MANAGE_WORKSPACE)) setCanManageWorkspace(true);
    if (userHasFeature(CAN_MANAGE_LABELS)) setCanManageLabels(true);
    if (userHasFeature(CAN_USE_GOVERNORATE_LAYER))
      setCanUseGovernorateLayer(true);
  }, [user]);

  const firebaseRegister = async () => {
    await auth.firebaseRegister();
  };
  const setFirebaseActive = async () => {
    await auth.setFirebaseActive();
  };
  const setFirebaseInactive = async () => {
    await auth.setFirebaseInactive();
  };

  const checkFirebaseRegister = async () => {
    await auth.checkFirebaseRegister();
  };
  if (!fetched) {
    getUser().then(() => setFetched(true));
  }

  if (!fetched) {
    return <LoadingPage />;
  }

  return (
    <AuthenticationContext.Provider
      value={{
        user,
        login,
        logout,
        getUser,
        isFacilityManager,
        isBranchManager,
        isDivisionManager,
        isFeedbackOnly,
        isAppOnly,
        isDemo,
        setUser,
        firebaseRegister,
        setFirebaseActive,
        setFirebaseInactive,
        checkFirebaseRegister,
        setUserProfile,
        canSendSMS,
        canManageDivisions,
        canManageUsers,
        canUseSettings,
        canUseFeedbackInvitations,
        canArchiveReviews,
        canUseReputationDashboard,
        canArchiveComplaints,
        canManageWorkspace,
        canManageLabels,
        canUseGovernorateLayer,
        hasSignedInGoogle,
      }}
    >
      {props.children}
    </AuthenticationContext.Provider>
  );
};

export { AuthenticationContextProvider };
