import { SettingsContextProvider } from "../contexts/SettingsContext";
import {
  AdminContextProvider,
  FacilityManagementScreen,
} from "../features/facility-management";
import {
  ExternalProgressPage,
  SingleBranchProgressScreen,
} from "../features/overall-view";
// import { FeedbackScreen } from "../features/feedback-page";
import {
  ResetPasswordPageScreen,
  ForgotPasswordScreen,
} from "../features/login";
import { SignUpPageScreen } from "../features/signup";
import { NotFoundPage } from "../reusable/components/NotFoundPage";
import { TokenVerify } from "../features/signup/components";
import {
  ReportsHubScreen,
} from "../features/reports-hub";
import { RevampedOverallViewScreen } from "../features/revamped-overall-view";
import { OutboundFeedbackScreen } from "../features/outbound-feedback";
import { LinkGeneratorScreen } from "../features/link-generator";
import { SettingsScreen } from "../features/settings/screens/SettingsScreen";
import { RevampedGeneralSettingsScreen } from "../features/revamped-general-settings";
import { TabbedLandingPageScreen } from "../features/tabbed-landing-page";
import { DistributionScreen } from "../features/distribution";

const publicRoutes = [
  {
    path: "/forgot-password",
    element: <ForgotPasswordScreen />,
  },
  {
    path: "/password-reset/confirm/:uid/:token",
    element: <ResetPasswordPageScreen />,
  },
  {
    path: "/registration/verify",
    element: <TokenVerify type="newFacility" />,
  },
  {
    path: "/registration/:type",
    element: <SignUpPageScreen />,
  },
  {
    path: "/user_invite/verify",
    element: <TokenVerify type="invite" />,
  },
  {
    path: "/user_invite/:type",
    element: <SignUpPageScreen />,
  },
];

const allButFeedbackOnlyUsersProtectedRoutes = [
  {
    path: "/:landingPageTab",
    element: <TabbedLandingPageScreen />,
  },
  {
    path: "branches/:branchID/progress",
    element: <SingleBranchProgressScreen />,
  },
  //   {
  //     path: "/feedback/:feedbackTab",
  //     element: <FeedbackScreen />,
  //   },
  {
    path: "/outbound",
    element: <OutboundFeedbackScreen />,
  },
  {
    path: "/link-generator",
    element: <LinkGeneratorScreen />,
  },
  {
    path: "/distribution",
    element: <DistributionScreen />,
  },
  {
    path: "/facility-management/:facilityManTab",
    element: (
      <SettingsContextProvider>
        <AdminContextProvider>
          <FacilityManagementScreen />
        </AdminContextProvider>
      </SettingsContextProvider>
    ),
  },
  {
    path: "/settings/:settingsTab",
    element: <SettingsScreen />,
  },
  {
    path: "/revamped-settings/:revampedSettingsTab",
    element: (
      <SettingsContextProvider>
        <RevampedGeneralSettingsScreen />
      </SettingsContextProvider>
    ),
  },
  {
    path: "/overall-progress",
    element: <ExternalProgressPage />,
  },
  { path: "/reports-hub", element: <ReportsHubScreen /> },
  {
    // Fallback Route
    path: "*",
    element: <NotFoundPage />,
  },
];

const allUsersProtectedRoutes = [
  //   {
  //     path: "/feedback/:feedbackTab",
  //     element: <FeedbackScreen />,
  //   },
  {
    path: "/outbound",
    element: <OutboundFeedbackScreen />,
  },
  {
    path: "/link-generator",
    element: <LinkGeneratorScreen />,
  },
  {
    path: "/overall-view/:revampedOverallViewTab",
    element: <RevampedOverallViewScreen />,
  },
];

/**
 * Redirection Rules without "to" destination are dynamic
 * and will be updated based on the logged-in User Role
 */
const redirectionRules = [
  {
    from: "/overall-view",
  },
  {
    from: "/facility-management",
    to: "/facility-management/issues",
  },
  {
    from: "/settings",
    to: "/settings/profile",
  },
  {
    from: "/revamped-settings",
    to: "/revamped-settings/profile",
  },
];

export {
  publicRoutes,
  allButFeedbackOnlyUsersProtectedRoutes,
  allUsersProtectedRoutes,
  redirectionRules,
};
