import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export const GenericAccordion = ({ title, body, backgroundColor, disabled, preventExpansion, onClickCallback }) => {
  return (
    <Accordion disabled={disabled} sx={{bgcolor: backgroundColor}} onClickCapture={function(e){
        preventExpansion && e.stopPropagation();
        onClickCallback && onClickCallback();
    }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={{margin: '0px !important'}}
      >
        {title}
      </AccordionSummary>
      <AccordionDetails>{body}</AccordionDetails>
    </Accordion>
  );
};