import UserErrorSuccessFormDisplay from "../../reusable/components/UserErrorSuccessFormDisplay";
import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { COLORS } from "../../helpers/Colors";
import { primaryColors, secondaryColors } from "../../helpers/customColors";
import {
  fontSizeVariations,
  fontWeightVariations,
} from "../../helpers/customFont";
import { ReactComponent as Logo } from "../../assets/melior-logo-black.svg";

const useStyles = makeStyles((props) => ({
  body: {
    height: "fit-content",
    marginTop: "10px",
    fontSize: "1.4rem",
    textAlign: "center",
    display: "flex",
    color: COLORS.brightBlack,
  },
  properPadding: {
    padding: "0px 20px",
  },
  headline: {
    width: "100%",
    margin: "5px auto",
    fontFamily: props.language ? "BalooBhaijaan2" : "Nunito",
    fontWeight: fontWeightVariations.semiBold,
    fontSize: fontSizeVariations["text-xl"],
    color: primaryColors.gray[900],
  },
  row: {
    justifyContent: "space-between",
    width: "95%",
    margin: "0 auto",
    padding: "5px",
  },
  meliorlogo: {
    width: "5rem",
    margin: "2px 10px",
    height: "auto",
  },
  title: {
    width: "95%",
    margin: "5px auto 10px",
    fontSize: "1.5rem",
  },
  logo: {
    width: "80%",
    display: "block",
    height: "auto",
    objectFit: "contain",
    margin: "5px auto",
  },
}));

const OnlineSurveyThankYouCard = (props) => {
  const classes = useStyles(props);
  return (
    <Grid
      container
      justifyContent="center"
      alignContent="flex-start"
      className={classes.body}
    >
      {/* <div className={classes.row}>
        <Logo className={classes.meliorlogo} />
      </div> */}
      <div className={classes.row}>
        {props.visited && (
          <UserErrorSuccessFormDisplay
            style={{
              background: "transparent",
              width: "90%",
            }}
            fontSize={fontSizeVariations["text-sm"]}
            language={props.language}
            color={primaryColors.error[700]}
            type="error"
            message={
              "Please be informed that you have already submitted the survey and further submissions are not allowed"
            }
          />
        )}
      </div>
      {!props.facilityData ? null : (
        <div className={classes.row}>
          {!props.facilityData.image ? (
            <Logo className={classes.logo} style={{ visibility: "hidden" }} />
          ) : (
            <img
              className={classes.logo}
              alt={props.facilityData.name}
              src={props.facilityData.image}
            />
          )}
        </div>
      )}

      <div
        className={classes.row}
        style={{
          flexDirection: "column",
          height: "fit-content",
          alignSelf: "stretch",
          justifyContent: "center",
          alignItems: "center",
          gap: "16px",
        }}
      >
        <div className={classes.headline} dir="auto">
          {props.language ? "شكرا جزيلا" : "Thank you!"}
        </div>
        <div className={classes.headline} dir="auto">
          {props.language
            ? "رأيك وتقييمك لجودة الخدمة يساعدنا على تطوير أدائنا"
            : "Your feedback will help us improve our services."}
        </div>
      </div>
    </Grid>
  );
};

export default OnlineSurveyThankYouCard;
