import {
  Box,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { useEffect } from "react";
import {
  useGetAllBranchesWithoutPagination,
  useGetBranchDetails,
  useGetDivisionTemplates,
} from "../../features/settings";
import { primaryColors } from "../../helpers/customColors";

export const TemplateSelection = ({
  templateSelectionState,
  selectBranchHandler,
  selectDivisionHandler,
  selectTemplateHandler,

  externalDefaultBranchID = undefined,
}) => {
  const { data: branchesResponse } = useGetAllBranchesWithoutPagination();

  const selectedBranchID = externalDefaultBranchID
    ? externalDefaultBranchID
    : templateSelectionState.branchData?.id;

  const {
    data: branchDetailsResponse,
    isFetching: isFetchingBranchDetailsResponse,
  } = useGetBranchDetails({
    params: {
      branchID: selectedBranchID,
    },
    config: {
      enabled: selectedBranchID !== undefined,
    },
  });

  const {
    data: divisionTemplatesResponse,
    isFetching: isFetchingDivisionTemplatesResponse,
  } = useGetDivisionTemplates({
    params: { divisionID: templateSelectionState.divisionData?.id },
    config: {
      enabled: templateSelectionState.divisionData?.id !== undefined,
    },
  });

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      rowGap="10px"
      columnGap={{ xs: "10px", md: "10%" }}
    >
      <Grid item>
        <BranchSelectMenu
          branchesData={branchesResponse?.data}
          selectedBranchID={selectedBranchID}
          selectBranchHandler={selectBranchHandler}
          usesExternalDefaultBranchID={externalDefaultBranchID !== undefined}
        />
      </Grid>
      <Grid item>
        {/* {showDivisionSelectMenu && ( */}
        <DivisionSelectMenu
          divisionsData={branchDetailsResponse?.data.divisions}
          selectedDivisionID={templateSelectionState.divisionData?.id}
          selectDivisionHandler={selectDivisionHandler}
          isFetchingBranchDetailsResponse={isFetchingBranchDetailsResponse}
        />
        {/* )} */}
      </Grid>
      <Grid item>
        <TemplateSelectMenu
          templatesData={divisionTemplatesResponse?.data}
          selectedTemplateID={templateSelectionState.templateData?.id}
          selectedDivisionID={templateSelectionState.divisionData?.id}
          selectTemplateHandler={selectTemplateHandler}
          isFetchingDivisionTemplatesResponse={
            isFetchingDivisionTemplatesResponse
          }
        />
      </Grid>
    </Grid>
  );
};

const BranchSelectMenu = ({
  branchesData,
  selectBranchHandler,
  selectedBranchID,

  usesExternalDefaultBranchID,
}) => {
  return (
    <FormControl sx={{ bgcolor: primaryColors.base.white, width: "214px" }}>
      <InputLabel id="select-branch">Select Branch</InputLabel>
      <Select
        labelId="select-branch"
        value={selectedBranchID}
        label="Select Branch"
        onChange={function (event) {
          selectBranchHandler(
            branchesData.find(function getBranchDataByID(branchData) {
              return String(branchData.id) === String(event.target.value);
            })
          );
        }}
        defaultValue={selectedBranchID}
        disabled={branchesData === undefined || usesExternalDefaultBranchID}
      >
        {branchesData?.map(function renderBranchOption(branchData) {
          return <MenuItem value={branchData.id}>{branchData.name}</MenuItem>;
        })}
      </Select>
    </FormControl>
  );
};

const DivisionSelectMenu = ({
  divisionsData,
  selectDivisionHandler,
  selectedDivisionID,
  isFetchingBranchDetailsResponse,
}) => {
  let showDivisionSelectMenu = false;
  if (!isFetchingBranchDetailsResponse) {
    if (divisionsData?.length === 1) {
      showDivisionSelectMenu = selectedDivisionID !== undefined;
    } else if (divisionsData?.length > 1) {
      showDivisionSelectMenu = true;
    }
  }

  useEffect(
    function checkIfHasOneDivisionAndSelectIt() {
      /**
       * use isFetchingBranchDetailsResponse to not run into race condition, where selected divisionID might not be undefined yet
       * */
      if (
        !isFetchingBranchDetailsResponse &&
        selectedDivisionID === undefined &&
        divisionsData &&
        divisionsData.length === 1
      ) {
        selectDivisionHandler(divisionsData[0]);
      }
    },
    [
      selectDivisionHandler,
      selectedDivisionID,
      divisionsData,
      isFetchingBranchDetailsResponse,
    ]
  );

  return !showDivisionSelectMenu ? (
    <ReusableSelectMenuReplacement
      label="Select a Division"
      isLoading={isFetchingBranchDetailsResponse}
    />
  ) : (
    <FormControl sx={{ bgcolor: primaryColors.base.white, width: "214px" }}>
      <InputLabel id="select-division">Select Division</InputLabel>
      <Select
        labelId="select-division"
        value={selectedDivisionID}
        label="Select Division"
        onChange={function (event) {
          selectDivisionHandler(
            divisionsData.find(function getDivisionDataByID(divisionData) {
              return String(divisionData.id) === String(event.target.value);
            })
          );
        }}
        disabled={divisionsData === undefined || divisionsData?.length === 1}
      >
        {divisionsData?.map(function renderDivisionOption(divisionData) {
          return (
            <MenuItem value={divisionData.id}>{divisionData.name}</MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

const TemplateSelectMenu = ({
  templatesData,
  selectTemplateHandler,
  selectedTemplateID,
  selectedDivisionID,
  isFetchingDivisionTemplatesResponse,
}) => {
  let showTemplateSelectMenu = false;
  if (!isFetchingDivisionTemplatesResponse) {
    if (templatesData?.length === 1) {
      showTemplateSelectMenu = selectedTemplateID !== undefined;
    } else if (templatesData?.length > 1) {
      showTemplateSelectMenu = true;
    }
  }
  useEffect(
    function checkIfHasOneDivisionAndSelectIt() {
      // selectedDivisionID is used in the condition because there's a case where all will resolve to true when no division selected.
      if (
        !isFetchingDivisionTemplatesResponse &&
        selectedTemplateID === undefined &&
        selectedDivisionID !== undefined &&
        templatesData &&
        templatesData.length === 1
      ) {
        selectTemplateHandler(templatesData[0]);
      }
    },
    [
      isFetchingDivisionTemplatesResponse,
      templatesData,
      selectTemplateHandler,
      selectedTemplateID,
      selectedDivisionID,
    ]
  );

  return !showTemplateSelectMenu ? (
    <ReusableSelectMenuReplacement
      label="Select a Template"
      isLoading={isFetchingDivisionTemplatesResponse}
    />
  ) : (
    <FormControl sx={{ bgcolor: primaryColors.base.white, width: "214px" }}>
      <InputLabel id="select-template">Select Template</InputLabel>
      <Select
        labelId="select-template"
        value={selectedTemplateID}
        label="Select Template"
        onChange={function (event) {
          selectTemplateHandler(
            templatesData.find(function getTemplateDataByID(templateData) {
              return String(templateData.id) === String(event.target.value);
            })
          );
        }}
        disabled={templatesData === undefined || templatesData?.length === 1}
      >
        {templatesData?.map(function renderTemplateOption(templateData) {
          return (
            <MenuItem value={templateData.id}>{templateData.name}</MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

const ReusableSelectMenuReplacement = ({ label, isLoading = false }) => {
  return (
    <Box position="relative">
      {isLoading && (
        <CircularProgress
          size="20px"
          color="secondary"
          sx={{ position: "absolute", top: "35%", left: "30%" }}
        />
      )}

      <Box
        px={"10px"}
        border={1}
        borderColor={primaryColors.gray[300]}
        bgcolor={primaryColors.base.white}
        borderRadius={1}
        width={"214px"}
        height={"56px"}
        boxSizing="border-box"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          cursor: "default",
          opacity: isLoading ? 0.5 : 1,
        }}
      >
        <Typography color={primaryColors.gray[400]}>{label}</Typography>
        <Box
          width={0}
          height={0}
          borderLeft={`6px solid transparent`}
          borderRight={`6px solid transparent`}
          borderTop={`6px solid ${primaryColors.gray[400]}`}
        />
      </Box>
    </Box>
  );
};
