import { ReputationResponsePlatforms } from "../features/revamped-overall-view/types/reputation-reponses";
import { removeBreakingCharacters } from "./excelHelpers";
import StringHelpers from "./StringHelpers";

const reformDataForComplaints = (data) => {
  let reformedData = [];

  data.forEach((record) => {
    let currReformed = {};
    const complaintActions = record.action_history.actions;
    const latestActionInComplaint =
      complaintActions[complaintActions.length - 1];

    currReformed["submitted"] = record["submitted_at"]
      ? reformDate(record["submitted_at"])
      : "";
    currReformed["Complaint_Number"] = record.id ? record.id : "_";
    currReformed["submittedAndId"] =
      record.id + " - " + currReformed["submitted"];

    currReformed.issue = record["issue"] ? record["issue"] : "others";

    currReformed["Latest_Action"] =
      complaintActions.length === 0 ? "-" : latestActionInComplaint.action;
    currReformed["Action_Comment"] =
      complaintActions.length === 0
        ? "-"
        : removeBreakingCharacters(
            latestActionInComplaint[
              latestActionInComplaint.action === "note" ? "text" : "comment"
            ]
          );

    currReformed.contact = record.contacts;

    currReformed.commentText = record["comment_text"]
      ? removeBreakingCharacters(record["comment_text"])
      : "";
    currReformed.commentAudio = record["comment_audio"];
    currReformed.comment = record["comment_text"] || record["comment_audio"];

    currReformed.channel = record["channel"] ? record["channel"] : "";
    currReformed.bookmarked = record["bookmarked"]
      ? record["bookmarked"]
      : false;
    currReformed.resolved = record["resolved"] ? record["resolved"] : false;
    currReformed.hasNote =
      record["complaint_notes"] && record["complaint_notes"].length > 0
        ? true
        : false;
    currReformed.notes = record["complaint_notes"]
      ? record["complaint_notes"]
      : [];
    reformedData.push(currReformed);
  });

  return reformedData;
};

const reformDataForComplaintsRevamped = (data) => {
  let reformedData = [];

  data.forEach((record) => {
    let currReformed = {
      id: record.id,
      Submitted_At: record.submitted_at,
      MRN: record.mrn || '-',
      Created_By: record.created_by?.username || "-",
      Classification_Labels: record.labels.map(labels => labels.text).join(", ") || '-', 
      Related_Teams: record.related_teams.map(team => team.name).join(", ") || '-',
      Assignees: record.assignees.map(assignee => assignee.username).join(", ") || '-',
      Branch: record.branch.name,
      Division: record.division,
      Channel: record.channel,
      Issue: record.issue,
      Comment: removeBreakingCharacters(record.comment_text) || '-',
      Comment_Audio: record["comment_audio"] || '-',
      Customer_Name: !!record.contacts?.name
        ? removeBreakingCharacters(record.contacts.name)
        : "-",
      Customer_Phone: !!record.contacts?.number
        ? record.contacts.number
        : !!record.contacts?.phone
        ? record.contacts.phone
        : "-",
    };

    reformedData.push({ ...currReformed });
  });

  return reformedData;
};

const reformDataForReputationResponses = (data) => {
  let reformedData = [];

  data.forEach((record) => {
    const reviewTitle = record.review_details.title;
    const reviewText = record.review_details.text;

    let currReformed = {
      id: record.id ?? "N/A",
      Platform: StringHelpers.capitalizeFirstLetter(record.platform),
      Status: StringHelpers.capitalizeFirstLetter(record.status),
      Submitted_On: record.submitted_on,
      Branch: record.branch.name,
      Review_Title: removeBreakingCharacters(reviewTitle) ?? "N/A",
      Review_Message: removeBreakingCharacters(reviewText) ?? "N/A",
      Review_URL: removeBreakingCharacters(record.review_details.url) ?? "N/A",
      Customer_Name: record.name_or_username
        ? removeBreakingCharacters(record.name_or_username)
        : "N/A",
      Rating: record.rating,
    };

    reformedData.push({ ...currReformed });
  });

  return reformedData;
};

const reformDate = (dateIn) => {
  let date = new Date(dateIn);
  let submittedStr = "";
  let month = date.toDateString().split(" ")[1];
  let hours = date.getHours();
  let timeOfDay = "AM";
  if (hours >= 12) {
    timeOfDay = "PM";
  }
  if (hours > 12) {
    hours -= 12;
  }
  let mins = date.getMinutes();

  if (mins <= 9) {
    mins = "0" + mins;
  }

  if (month.length === 1) {
    month = "0" + month;
  }

  if (hours <= 9) {
    hours = "0" + hours;
  }

  submittedStr +=
    hours +
    ":" +
    mins +
    " " +
    timeOfDay +
    " " +
    date.getDate() +
    " " +
    month +
    " " +
    date.getFullYear();

  return submittedStr;
};

export default {
  reformDataForComplaints,
  reformDataForComplaintsRevamped,
  reformDate,
  reformDataForReputationResponses,
};
