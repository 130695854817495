import { Box, Skeleton, Typography } from "@mui/material";
import MUIReactTable from "material-react-table";
import { insightSemantics } from "../../../revamped-landing-page/components/RevampedLandingPage";
import { fontWeightVariations } from "../../../../helpers/customFont";
import { primaryColors } from "../../../../helpers/customColors";
import { renderReadableMilliseconds } from "../../../../helpers/timeHelpers";
import { useMemo } from "react";
import { getASBarColor } from "../../../revamped-landing-page/components/AllBranchesQuestionBarChart";
import { renderIconForSemantic } from "../../../revamped-landing-page/components/ChangeInRateBadge";

export const ComparisonTable = ({
  tableComparisonAPIData,
  isFetching,
  selectedRankingOption,
}) => {
  const columnsData = useMemo(
    function prepareTableColumns() {
      if (tableComparisonAPIData)
        return prepareTableColumnsFromRawBranchesComparisonData({
          rawBranchesComparisonData: tableComparisonAPIData,
          selectedRankingOption,
        });
    },
    [selectedRankingOption, tableComparisonAPIData]
  );

  return isFetching || !tableComparisonAPIData ? (
    <SkeletonForComparisonTable />
  ) : (
    <MUIReactTable
      layoutMode="grid"
      muiTableHeadCellProps={{
        sx: {
          backgroundColor: primaryColors.gray[50],
        },
      }}
      muiTablePaperProps={{
        sx: {
          width: "100%",
          boxShadow: "none",
        },
      }}
      initialState={{
        sorting: [{ id: "CSAT", desc: true }],
      }}
      // muiTableBodyCellProps={{
      //   sx: {
      //     size: 100,
      //   },
      // }}
      state={{ isLoading: isFetching }}
      columns={columnsData}
      data={prepareTableDataFromRawBranchesComparisonData(
        tableComparisonAPIData
      )}
      rowNumberMode="static"
      enableRowNumbers={true}
      enableColumnActions={false}
      enableHiding={false}
      enableColumnFilters={false}
      enablePagination={false}
      enableBottomToolbar={false}
      enableTopToolbar={false}
    />
  );
};

const SkeletonForComparisonTable = () => {
  return (
    <Skeleton
      variant="rectangular"
      width={"100%"}
      height={400}
      sx={{ borderRadius: 5 }}
    />
  );
};
function prepareTableColumnsFromRawBranchesComparisonData({
  rawBranchesComparisonData,
  selectedRankingOption,
}) {
  let allColumns = [];
  const nameColumn = {
    header: selectedRankingOption === "rank_branches" ? "Branch" : "Division",
    accessorKey: "branchName",
    muiTableBodyCellProps: {
      sx: {
        color: primaryColors.base.black,
      },
    },
    size: 100,
  };

  allColumns.push(nameColumn);
  const branchData = rawBranchesComparisonData[0];
  branchData?.insights.forEach(function generateReactTableColumn(
    branchInsight
  ) {
    allColumns.push({
      header: branchInsight.label,
      size: 100,
      maxSize: 200,
      sortingFn: (rowA, rowB, columnHeader) => {
        const rowAValue = rowA.original[columnHeader].value;
        const rowBValue = rowB.original[columnHeader].value;

        if (rowAValue < rowBValue) {
          return -1;
        } else if (rowAValue === rowBValue) {
          return 0;
        } else {
          return 1;
        }
      },
      accessorFn: function renderbranchInsight(rowData) {
        const currentInsightData = rowData[branchInsight.label];
        switch (currentInsightData.type) {
          case insightTypes.plainNumber: {
            return currentInsightData.value;
          }
          case insightTypes.performanceOverTime: {
            const value =
              currentInsightData.label === "CSAT"
                ? `${currentInsightData.value}%`
                : currentInsightData.value;
            switch (currentInsightData.semantic) {
              case insightSemantics.positive: {
                return (
                  <ProgressOverTimeInsightBadge
                    lightColor={primaryColors.success[50]}
                    solidColor={primaryColors.success[700]}
                    value={value}
                    arrow={currentInsightData.arrow}
                    semantic={currentInsightData.semantic}
                  />
                );
              }
              case insightSemantics.neutral: {
                return (
                  <ProgressOverTimeInsightBadge
                    lightColor={primaryColors.warning[50]}
                    solidColor={primaryColors.warning[700]}
                    value={value}
                    arrow={currentInsightData.arrow}
                    semantic={currentInsightData.semantic}
                  />
                );
              }
              case insightSemantics.negative: {
                return (
                  <ProgressOverTimeInsightBadge
                    lightColor={primaryColors.error[50]}
                    solidColor={primaryColors.error[700]}
                    value={value}
                    arrow={currentInsightData.arrow}
                    semantic={currentInsightData.semantic}
                  />
                );
              }
              default: {
                return null;
              }
            }
          }
          case insightTypes.time: {
            let { readableTime, timeUnit } = renderReadableMilliseconds(
              currentInsightData.value
            );
            return `${readableTime} ${timeUnit}`;
          }
          case insightTypes.percentage: {
            return `${currentInsightData.value}%`;
          }
          case insightTypes.rating: {
            return (
              <ProgressOverTimeInsightBadge
                lightColor={getASLightColor(currentInsightData.value)}
                solidColor={getASBarColor(currentInsightData.value)}
                value={currentInsightData.value}
              />
            );
          }
          default: {
            return null;
          }
        }
      },
    });
  });

  return [...allColumns];
}

function prepareTableDataFromRawBranchesComparisonData(
  rawBranchesComparisonData
) {
  const tableData =
    rawBranchesComparisonData?.map(function mapRawBranchDataToTableRow(
      rawBranchData
    ) {
      let dynamicBranchData = {};
      rawBranchData?.insights.forEach(function prepareDynamicBranchInsights(
        branchInsight
      ) {
        dynamicBranchData[branchInsight.label] = {
          label: branchInsight.label,
          semantic: branchInsight.semantic,
          type: branchInsight.type,
          value: branchInsight.value,
          arrow: branchInsight.arrow,
        };
      });
      return {
        branchName: rawBranchData.name,
        ...dynamicBranchData,
      };
    }) || [];

  return [...tableData];
}

const ProgressOverTimeInsightBadge = ({
  lightColor,
  solidColor,
  value,
  arrow,
  semantic,
}) => {
  return (
    <Box
      p={1}
      borderRadius={4}
      //   width="fit-content"
      display="flex"
      alignItems="center"
      justifyContent="center"
      gap={0.7}
      width="72px"
      height="24px"
      bgcolor={lightColor}
      color={solidColor}
      boxSizing="border-box"
    >
      {arrow && renderIconForSemantic({ arrow, semantic })}
      <Typography
        variant="text-sm"
        sx={{ fontWeight: fontWeightVariations.semiBold }}
      >
        {value}
      </Typography>
    </Box>
  );
};

const getASLightColor = (value) => {
  if (value <= 1) return primaryColors.error[50];
  else if (value <= 2) return primaryColors.warning[50];
  else if (value <= 3) return primaryColors.brand[50];
  else if (value <= 4) return primaryColors.success[50];
  else if (value <= 5) return primaryColors.success[50];
};

export const insightTypes = {
  performanceOverTime: "performance_over_time",
  plainNumber: "plain_number",
  time: "time",
  percentage: "percentage",
  rating: "rating",
};
